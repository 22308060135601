
.session-assignment-panel{

  .assignment-badges{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .badge-assigned-user{
    margin-right: 0.3rem;
    > i {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      cursor: pointer;
    }
  }
  .balance-warning{
    padding: .5rem;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    border: 1px solid darken(#f5ca99, 10%);
    > strong {
      color: darken(#f5ca99, 50%);
    }
  }
}
