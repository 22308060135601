
.client-reset-passcode{
    margin-top: 0.2rem;
    a{
        color: #fff;
    }

}
.client-reset-passcode-dialog{
    .instruction{
        margin-bottom: 1rem;
    }
}
