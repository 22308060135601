.session-change-histories-panel{
  .card-header{
    padding-bottom: 0.5rem;
  }

  > .title{
    font-weight: bold;
  }

  .histories {
    .history-item{
      margin-bottom: 0.3rem;
      border-bottom: 1px solid #e5e5e5;
      color: #444;
      padding: 0.5rem 0.2rem;

      .history-summary{
        font-size: small;
        white-space: pre-wrap;
      }

      .history-owner {
        font-size: small;
        text-align: right;
        color: #a7a7a7;
      }
    }

    .empty-data{
      text-align: center;
      color: #e5e5e5;
      font-size: small;
    }
  }
}
