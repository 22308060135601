.admin-app{

  @media (min-width: 1200px){
    .container{
      max-width: 1800px;
    }
  }

  header > .container{
    display: block;
  }

  main {
    padding-top: 2rem;
  }

  .navbar {
    display: block;

    .navbar-brand{
      .navbar-brand-logo{
        min-width: 1.3rem;
        max-width: 1.3rem;
        margin: -0.5rem 0.5rem 0 0.5rem;
      }

      font-family: 'Roboto Condensed', sans-serif;
      font-size: 1.4rem;
      color: #932092;
    }

    .current-user-name{
      color: #932092;
      font-size: 1rem;
      line-height: 3.5rem;
    }
  }

  main{
    display: block;
  }
}
