.change-user-token{
  padding-top: 1.6rem;

  .client-name{
    font-weight: bold;
    font-size: 2.2rem;
    text-align: right;
  }

  .buttons {
    text-align: right;
    > .btn{
      width: 9rem;
    }
  }
}
