  .style-switch{
    margin-bottom: 1rem;
    margin-right: 2rem;

    > i{
      margin-right: 0.3rem;
      display: inline-block;
      cursor: pointer;

      &:hover{
        color: #9e5f04;
      }
    }
  }
