
.class-session-form{

  .clone-params{
    border: solid 1px #fbc77e;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #ffe3cd;

    label{
      color: darken(#fbc77e, 50%);
    }

    .clone-description{
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
  ul.customer-local-time{
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    >li{
      float: left;
      margin-right: 0.2rem;
      >span.country{
        font-weight: bold;
        margin-right: 0.2rem;
      }
      &:after {
        content: ' | ';
      }
      &:last-child:after{
        content: '';
      }
    }
  }

  .meeting-link-url {
    margin-top: 1rem;
  }
}
