
$item-left-width: 2rem;
$item-right-width: 1.8rem;
$item-height: 7rem;
.class-session-list-box{
  .session-list{
    &-item{
      min-height: $item-height;
      cursor: pointer;
      border: 2px solid #e4e4e4;
      margin-bottom: 0.4rem;

      &:hover, &.selected {
        border: 2px solid #0049d0;
        background-color: #fbfbfb;
      }

      >.item-left{
        width: $item-left-width;
        height: $item-height;
        background-color: lighten(#377dff, 10%);
        float: left;
        .item-id{
          padding: 0.3rem;
          background-color: darken(#377dff, 20%);
          color: white;
          text-align: center;
        }
        .item-lock{
          padding: 1rem 0.3rem 0.3rem 0.3rem;
          color: #fff;
          font-weight: bold;
          text-align: center;
        }
      }

      >.item-right{
        float: right;
        text-align: center;
        width: $item-right-width;
        height: $item-height;
        border-left: 1px solid #eee;
        padding: 1rem 0.3rem;
        > i{
          display: block;
          margin-bottom: 0.6rem;
        }
      }

      >.item-center{
        padding-left: $item-left-width + 0.0;
        padding-right: $item-right-width + 0.0;
        height: $item-height;

        .item-center-title{
          font-weight: bold;
          height: 2rem;
          border-bottom: 1px solid #eee;
          padding: 0.2rem 0.5rem;
        }

        .item-center-body{
          height: $item-height - 3.4;
          padding: 0.5rem 1rem;

          .student-name{
            font-size: 1.5rem;
            font-weight: bold;
          }
        }

        .item-center-footer{
          height: 1.4rem;
          font-size: small;
          background-color: #e8e8e8;
          padding: 0.2rem 0.5rem;

          ul.item-status-bar{
            list-style: none;
            margin-left: 0;
            padding-left: 0;
            overflow: hidden;
            > li{
              float: left;
              min-width: 4rem;
              margin-right: 0.3rem;

              >label{
                font-weight: bold;
                &:after{
                  content: ": ";
                }
              }
              >span{
                min-width: 4rem;
                display: inline-block;
                padding-left: 0.2rem;
              }
              &:after{
                content: "|";
                //color: #0049d0;
                color: #cdcdcd;
                width: 1rem;
                margin: 0 0.2rem;
              }
              &:last-child:after{
                content: "";
              }
            }
          }
        }
        @media (max-width: 400px){
          .item-center-footer {
            display: none;
          }
        }
      }
    }

    .session-completed{
      &:hover, &.selected {
        border: 2px solid darken(#00c9a7, 20%);
      }
      >.item-left{
        //background-color: #00c9a7;
        .item-id{
          background-color: darken(#00c9a7, 20%);
        }
      }
    }

    .session-scheduled{

      &:hover, &.selected {
        border: 2px solid darken(#f7d2a8, 20%);
      }

      >.item-left{
        //background-color: #f7d2a8;
        .item-id{
          background-color: darken(#f7d2a8, 20%);
        }
      }
    }

    .session-cancelled{
      &:hover, &.selected {
        border: 2px solid darken(#888, 20%);
      }
      >.item-left{
        //background-color: #888;
        .item-id{
          background-color: darken(#888, 20%);
        }
      }
    }
  }

    .fa-lock{
      color: #377dff;
    }
    .fa-unlock{
      //color: #d5d5d5;
    }
}
