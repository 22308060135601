// @import "~bootstrap/scss/bootstrap";

.app {
  text-align: left;
}

.app-body {
  //color: #282c34;
  min-height: 100vh;
  position: relative;
  // font-size: calc(10px + 2vmin);
  // color: white;
  padding: 1rem;
}

.hover-visible{
  display: none;
  &:hover{
    display: block;
  }
}

.hover-hidden{
  &:hover{
    display: none;
  }
}
