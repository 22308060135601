
.class-session-list-table{
  table{
    font-size: 0.9rem;
    > tbody {
      >tr{
        cursor: pointer;

        &:hover{
          background-color: #e5e5e5;
        }
        &.selected{
          background-color: #e5e5e5;
          //font-weight: 600;
          border: 1px solid darken(#e5e5e5, 20%);
        }
      }
    }

    .fa-lock{
      color: #377dff;
    }
    .fa-unlock{
      color: #d5d5d5;
    }
  }
}
