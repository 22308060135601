
.class-session-list{

  .class-session-table-section{
    margin-bottom: 1.5rem;
    .class-session-list-title{
      font-weight: bold;
      font-size: 1.3rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      color: rgb(30,32,34);
    }
  }
  .class-session-table{
    margin-bottom: 2rem;
    > thead > tr > th {
      background-color: transparent;
      border-bottom: solid 2px #377dff;
    }

    >tbody>tr {
      &:hover{
        td {
          //color: #fff;
          font-weight: bold;
        }
      }
    }

    >tbody > tr > td{
      &.join-session{
        text-align: center;
      }

      .btn-join{
        width: 7rem;
      }
      //.btn-join.disabled{
      //  background-color: #a5aebd;
      //}
    }

  }

  .table>:not(:last-child)>:last-child>*{
    border-bottom: solid 2px #377dff;
  }

}
