.app-body-nav{
  color: #fff;
  .page-title{
    font-size: 2rem;
    padding-top: 1.6rem;
  }

  .bg-purple{
    background-color: #932092;
  }
}
