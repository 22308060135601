.session-action-panel{
  margin-bottom: 1rem;
  .card-header{
    padding-bottom: 0.5rem;
  }


  .close-btn{
    cursor: pointer;

    &:hover {
      color: #0a6ebd;
    }
  }
  .session-action-section{
    margin-bottom: 1rem;
  }

  .session-subtitle {
    margin-bottom: 0.2rem;
  }

  ul.customer-local-time{
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
    >li{
      float: left;
      margin-right: 0.2rem;
      >span.country{
        font-weight: bold;
        margin-right: 0.2rem;
      }
      &:after {
        content: ' | ';
      }
      &:last-child:after{
        content: '';
      }
    }
  }

  .other-info{
  }

  .comments-section{
    margin-bottom: 0;
    > label{
      font-weight: bold;
    }
    .comments{
      padding: 0.5rem;
      border: 1px solid #c8c8c8;
      background-color: #fafafa;
      min-height: 8rem;
    }
  }

  .session-button-bar{
    button, .btn {
      margin: 0.5rem 0;
      width: 100%;
    }
  }

}
