.sync-calendar-button {
  &.fa {
    margin-right: 0.3rem;
    cursor: pointer;

    &.cal-on{
      color: #0c83e2;
    }
    &.cal-off{
      color: #1d2124;
    }
  }
}
