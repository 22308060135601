.client-app{

  .navbar {
    display: block;

    .navbar-brand{
      .navbar-brand-logo{
        min-width: 3rem;
        max-width: 3rem;
        margin: 0 1rem 0 1rem;
        margin-top: -0.5rem;
      }

      font-family: 'Roboto Condensed', sans-serif;
      font-size: 3rem;
      color: #932092;

    }
  }

  main{
    display: block;
  }
}
