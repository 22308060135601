$color2: #21325b;
$color: #377dff;
.edit-icon-button{
  cursor: pointer;
  margin: 0 0.2rem;

  color: $color;

  &.on{
    color: $color;
  }

  &:hover{
    color: darken($color, 10%);
  }

  &.disabled{
    color: #bebebe;
  }

  &[disabled]{
    color: #bebebe;
  }

}
